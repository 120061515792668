import React from 'react';
import '../../App.css';
import HeroSectionInfo from '../InfoHeroSection';
import CardsInfo from '../InfoContactLocation';
import Accomodation from '../InfoAccomodation';
import Transport from '../InfoTransport';
import OtherServices from '../InfoOtherServices';
import Footer from '../Footer';

function Info() {

  return (
    <React.Fragment>
      <HeroSectionInfo />  
      <CardsInfo />
      <Transport />
      <Accomodation />
      <OtherServices />
      <Footer />
    </React.Fragment>
  );
}

export default Info;