import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
        <h1>Upcoming events in 2024 </h1>
        <div className='cards__container'>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                    <CardItem 
                    src='images/junior.png'
                    text='SLOVAK JUNIOR Badminton Europe Junior circuit'
                    label='22-24th November'
                    path='/junior'
                    />
                    <CardItem 
                    src='images/U15.png'
                    text='SLOVAK YOUTH Badminton Europe U15 circuit'
                    label='22-24th November'
                    path='/u15'
                    />
                    <CardItem 
                    src='images/U17.png'
                    text='SLOVAK YOUTH Badminton Europe U17 circuit'
                    label='27-29th September'
                    path='/u17'
                    />
                    <CardItem 
                    src='images/U13.png'
                    text='SLOVAK YOUTH Badminton Europe U13 circuit'
                    label='27-29th September'
                    path='/u13'
                    />
                </ul>
            </div>
        </div>
    </div>
  );
}

export default Cards;