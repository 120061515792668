import React from 'react';
import CardImageHorizontal from './CardImageHorizontal';
import CardInfo from './CardBaseTransport';
import './Cards1.css';

function Accomodation() {
  return (
    <div>
      <div className='cards__next'>
        <h1>Accomodation</h1>
        <div className='cards__container'>
            <div className='cards__wrapper'>
                <ul className='cards__items'>
                  <CardInfo 
                    text1='Accommodation shall be ordered together with the entries by simply email to
                    sj.bedminton@gmail.com by entry deadline.'
                    text2='Hotel bills have to be paid by bank transfer before the
                    tournament or in cash (EUR) immediately after your arrival to
                    the organizer in the sports hall. You have to pay for the room, not for person.' 
               />
               </ul> 

                <ul className='cards__items'>
                    <CardImageHorizontal
                    src='images/hotelMsport.jpg'
                    tag='Breakfast included'
                    label='Penzión M-Sport'
                    address='Ostrov 363, 911 05 Trenčín'
                    price1='Double room = 80€/night'
                    price3='Tripple room = 95€/night'
                    distance='Located directly in the venue.'
                    />
                    </ul>
                    <ul className='cards__items'>
                    <CardImageHorizontal
                    src='images/hotelPodHradom.jpg'
                    tag='Breakfast included'
                    label='Hotel Pod Hradom ★★★'
                    address='Matúšova 12, 911 01 Trenčín'
                    price1='Double room = 85€/night'
                    price3='Tripple room = 100€/night'
                    distance='Located 1,4km from the venue.'
                    />
                    </ul>
                    <ul className='cards__items'>
                    <CardImageHorizontal
                    src='images/hotelGaborik.jpg'
                    tag='Breakfast included'
                    label='Hotel of Marián Gáborík ★★★'
                    address='Hodžova 6844, 911 01 Trenčín'
                    price1='Single room = 80€/night'
                    price2='Double room = 80€/night'
                    price3='Tripple room = 95€/night'
                    distance='Located 1,9km from the venue.'
                    />
                    </ul>
                    <ul className='cards__items'>
                    <CardImageHorizontal
                    src='images/hotelCityCentre.jpeg'
                    tag='Breakfast included'
                    label='Apartments in city-centre'
                    address='Matúšova 10, 911 01 Trenčín'
                    price3='Apartment (2-4 persons) = 160€/night'
                    description='Apartment includes: 2 separate double rooms, bathroom, kitchen, living room, washing machine.'
                    distance='Located 1,4km from the venue.'
                    />
                </ul>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Accomodation;