import React from 'react';
import CardTournamentInfo from './CardTournamentInfo';
import './Cards.css';
import './HeroSection.css'

function U17Info() {
  return (
    <div>
      <div className='cards'>
        <h1>Tournament information</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              <CardTournamentInfo 
                label1='Entry fees:'
                text1='Singles = 35€'
                text2='Doubles = 25€ per player'
                text5='Entry fees have to be paid by bank transfer before the event or in
                      cash (EUR) to the organizer during the event.'
              />
              <CardTournamentInfo 
                label1='Team Managers meeting:'
                text2='Thursday September 26, 2024 at 7 p.m. in the venue.'
                label2='Offcial brand of shuttles:'
                text4='KAWASAKI SHUTTLECOCK TEAM 1'
              />
            </ul>

            <div className='table-wrapper'>
            <table className='table'>
              <thead>
                <tr>
                  <th> 
                    27th September
                    <span className='label time'>9:00</span>
                  </th>
                  <th>
                    28th September
                    <span className='label time'>9:00</span>
                  </th>
                  <th>
                    29th September
                    <span className='label time'>9:00</span>
                  </th>
                </tr>
              </thead> 
              <tbody>
                <tr>   {/* first row */}
                  <td> {/* first column - Friday */}
                    <span className='label size'>64</span>
                    <span className='label discipline'>XD</span>
                    <span className='label discipline'>WS</span>
                    <span className='label discipline'>MS</span>   
                  </td>
                  <td> {/* second column - Saturday */}
                    <span className='label size'>32</span> 
                    <span className='label discipline'>XD</span>
                    <span className='label discipline'>WS</span>
                    <span className='label discipline'>MS</span>
                  </td>
                  <td> {/* third column - Sunday */}
                    <span className='label size'>SF</span> 
                    <span className='label discipline'>XD</span>
                    <span className='label discipline'>WS</span>
                    <span className='label discipline'>MS</span>
                  </td>
                </tr>
                <tr>   {/* second row */}
                  <td> {/* first column - Friday */}
                    <span className='label size'>32</span> 
                    <span className='label discipline'>WD</span>
                    <span className='label discipline'>MD</span>
                  </td>
                  <td> {/* second column - Saturday */}
                    <span className='label size'>16</span> 
                    <span className='label discipline'>XD</span>
                    <span className='label discipline'>WD</span>
                    <span className='label discipline'>MD</span>
                    <span className='label discipline'>WS</span>
                    <span className='label discipline'>MS</span>
                  </td>
                  <td> {/* third column - Sunday */}
                    <span className='label size'>Finals</span> 
                    <span className='label discipline'>XD</span>
                    <span className='label discipline'>WD</span>
                    <span className='label discipline'>MD</span>
                    <span className='label discipline'>WS</span>
                    <span className='label discipline'>MS</span>
                  </td>
                </tr>
                <tr>   {/* third row */}
                  <td>-</td> {/* first column - Friday */}
                  <td> {/* second column - Saturday */}
                    <span className='label size'>QF</span> 
                    <span className='label discipline'>XD</span>
                    <span className='label discipline'>WD</span>
                    <span className='label discipline'>MD</span>
                    <span className='label discipline'>WS</span>
                    <span className='label discipline'>MS</span>  
                  </td>
                  <td>-</td> {/* third column - Sunday */}
                </tr>
                <tr>   {/* forth row */}
                  <td>-</td> {/* first column - Friday */}
                  <td> {/* second column - Saturday */}
                    <span className='label size'>SF</span> 
                    <span className='label discipline'>WD</span> 
                    <span className='label discipline'>MD</span>
                  </td>
                  <td>-</td> {/* third column - Sunday */}
                </tr>
              </tbody>
            </table>
          </div>
          
            <ul className='cards__items'>
            <CardTournamentInfo 
              text1="ⓘ Provisional times can be changed subject to the number of entries.
            The final frame schedule (with the exact planned conclusion of all
            matches) shall be made at least 20 days before the start of the
            tournament and shall be published on the website."
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default U17Info;