import React from 'react';
import '../../App.css';
import './Tournaments.css';
import '../HeroSection.css';
import { Button } from '../Button';
import Footer from '../Footer';
import PricingU17 from '../U17Info';

export default function U17() {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.focus();
  };

  const handleButtonClick = (url) => {
    openInNewTab(url);
  };

  return (
    <React.Fragment>
      <div className='u17'>
        <div className='hero-container_tournament'>
          <h1>Slovak Youth U17</h1>
          <span className='label deadline'>Entry deadline: 27th August</span>
          {/* <span className='label deadline'>Entry opens: 19th June</span> */}
          <p>27-29th September 2024</p>

          <div className='tournaments-btns'>
            <Button 
              className='btns'
              buttonStyle='btn--outline'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('/documents/SlovakYouthU17Invitation.pdf')} /* link to external page */
            >
              INVITATION
            </Button>

            <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('https://bwf.tournamentsoftware.com/tournament/115C215C-0E57-4958-80F3-6859E91F7AC8')} /* link to external page */
            >
              REGISTER
            </Button>

            <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('https://bwf.tournamentsoftware.com/sport/draws.aspx?id=115c215c-0e57-4958-80f3-6859e91f7ac8')} /* link to external page */
            >
              DRAWS
            </Button>

            <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('https://bwf.tournamentsoftware.com/tournament/115C215C-0E57-4958-80F3-6859E91F7AC8')} /* link to external page */
            >
              ACCEPTANCE LIST
            </Button>
          </div>
          

          {/* <div className='tournaments-info-wrapper'>
            <div className='tournaments-info'>
            ⓘ Provisional times can be changed subject to the number of entries.
            The final frame schedule (with the exact planned conclusion of all
            matches) shall be made at least 20 days before the start of the
            tournament and shall be published on the website.
            </div>
          </div> */}
        </div>
      </div>
      
      <PricingU17 />
      <Footer />
    </React.Fragment>
  );
}
