import React from 'react';
import '../../App.css';
import './Tournaments.css';
import '../HeroSection.css';
import { Button } from '../Button';
import Footer from '../Footer';
import JuniorInfo from '../JuniorInfo';

export default function Junior() {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.focus();
  };

  const handleButtonClick = (url) => {
    openInNewTab(url);
  };

  return (
    <React.Fragment>
      <div className='junior'>
        <div className='hero-container_tournament'>
          <h1>Slovak Junior</h1>
          {/* <span className='label deadline'>Entry deadline: 22nd October</span> */}
          <span className='label deadline'>Entry opens: 27th August</span>
          <p>22-24th November 2024</p>

          <div className='tournaments-btns'>
            <Button 
              className='btns'
              buttonStyle='btn--outline'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('/documents/SlovakJunior2024Invitation.pdf')} /* link to external page */
            >
              INVITATION
            </Button>

            <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('https://bwf.tournamentsoftware.com/tournament/D80C267A-B5FE-4A2E-BA64-8D61B22AE5B3')} /* link to external page */
            >
              REGISTER
            </Button>

            <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('https://bwf.tournamentsoftware.com/sport/draws.aspx?id=D80C267A-B5FE-4A2E-BA64-8D61B22AE5B3')} /* link to external page */
            >
              DRAWS
            </Button>

            <Button 
              className='btns'
              buttonStyle='btn--primary'
              buttonSize='btn--large'
              onClick={() => handleButtonClick('https://bwf.tournamentsoftware.com/tournament/D80C267A-B5FE-4A2E-BA64-8D61B22AE5B3')} /* link to external page */
            >
              ACCEPTANCE LIST
            </Button>
          </div>
          
          

          {/* <div className='tournaments-info-wrapper'>
            <div className='tournaments-info'>
            ⓘ Provisional times can be changed subject to the number of entries.
            The final frame schedule (with the exact planned conclusion of all
            matches) shall be made at least 20 days before the start of the
            tournament and shall be published on the website.
            </div>
            <div className='tournaments-info'>
            Team Managers meeting will be held on Thursday November 21, 2024 at 7 p.m. in the venue.
            </div>
          </div> */}

          

        </div>
      </div>
      <JuniorInfo />
      <Footer />
    </React.Fragment>
  );
}
